.container___1uQFc #components-form-demo-normal-login .login-form {
  width: 100%;
  max-width: 300px;
  height: calc(100vh);
  min-height: 100%;
  margin: 0px auto;
  padding-top: 100px;
}
.container___1uQFc #components-form-demo-normal-login .login-form-forgot {
  float: right;
}
.container___1uQFc #components-form-demo-normal-login .login-form-button {
  width: 100%;
}
