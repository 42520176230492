/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@media screen and (max-width: 480px) {
  .umi-plugin-layout-container {
    width: 100% !important;
  }
  .umi-plugin-layout-container > * {
    border-radius: 0 !important;
  }
}
.umi-plugin-layout-menu :global(.anticon) {
  margin-right: 8px;
}
.umi-plugin-layout-menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.umi-plugin-layout-right {
  display: flex;
  float: right;
  height: 100%;
  margin-left: auto;
  overflow: hidden;
}
.umi-plugin-layout-right .umi-plugin-layout-action {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.umi-plugin-layout-right .umi-plugin-layout-action > i {
  color: rgba(0, 0, 0, 0.85);
  vertical-align: middle;
}
.umi-plugin-layout-right .umi-plugin-layout-action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.umi-plugin-layout-right .umi-plugin-layout-action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}
.umi-plugin-layout-right .umi-plugin-layout-search {
  padding: 0 12px;
}
.umi-plugin-layout-right .umi-plugin-layout-search:hover {
  background: transparent;
}
.umi-plugin-layout-name {
  margin-left: 8px;
}
